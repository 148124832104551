import { flip, offset, shift, useClientPoint, useFloating, useInteractions } from '@floating-ui/react'
import classnames from 'classnames'
import * as React from 'react'

import '../../../../styles/map-hover.css'
import Sparkline from '../../shared/components/Sparkline'
import MapActions from '../store/action-creators'
import MapStore from '../store/mapStore'
import Treemap from '../treemap'
import { MapDataNode, MapTypeId } from '../types'
import { getVisibleTooltipNodes } from '../utils'

interface CanvasHoverProps {
  treemap: Treemap
}

interface CanvasHoverState {
  hoveredNode?: MapDataNode | null
  visibleNodes: MapDataNode[]
  sparklineData: Record<string, number[]>
}

const formatPerf = (d: MapDataNode, treemap: Treemap) => {
  if (typeof d.additional !== 'undefined' && d.additional !== null) {
    return d.additional
  } else if (typeof d.perf !== 'undefined' && d.perf !== null) {
    return treemap.formatValue(d.perf.toFixed(2))
  } else {
    return 'N/A'
  }
}

export default function CanvasHover(props: CanvasHoverProps) {
  const prevDataHash = React.useRef(props.treemap.dataHash)
  const [{ hoveredNode, visibleNodes, sparklineData }, setState] = React.useState<CanvasHoverState>({
    hoveredNode: null,
    visibleNodes: [],
    sparklineData: {},
  })

  /**
   * Compute position using floating ui
   */
  const { refs, floatingStyles, context, isPositioned, update } = useFloating({
    open: !!hoveredNode,
    strategy: 'fixed',
    placement: 'right-start',
    middleware: [offset({ mainAxis: 100 }), flip({ crossAxis: false }), shift()],
  })
  const clientPoint = useClientPoint(context)
  const { getFloatingProps } = useInteractions([clientPoint])

  /**
   * Update state when store changes
   */
  React.useEffect(() => {
    const handleMapStoreChange = () => {
      const storeHoveredNode = MapStore.getHoveredNode(props.treemap.mapNodeId)
      const sparklineData = MapStore.getSparklines()
      const visibleNodes = getVisibleTooltipNodes(storeHoveredNode)

      if (storeHoveredNode && storeHoveredNode.name !== hoveredNode?.name) {
        const tickersToLoad = visibleNodes
          .filter((node) => (props.treemap.dataHash !== prevDataHash.current ? true : !sparklineData[node.name]))
          .map((node) => node!.name)
        if (
          storeHoveredNode &&
          !sparklineData[storeHoveredNode.name] &&
          !tickersToLoad.includes(storeHoveredNode?.name)
        ) {
          tickersToLoad.push(storeHoveredNode.name)
        }

        prevDataHash.current = props.treemap.dataHash
        MapActions.loadSparklineData(tickersToLoad, props.treemap.type)
      }

      setState((prevState) => ({ ...prevState, hoveredNode: storeHoveredNode, visibleNodes, sparklineData }))
    }

    MapStore.addChangeListener(handleMapStoreChange)

    return () => {
      MapStore.removeChangeListener(handleMapStoreChange)
      MapActions.loadSparklinesCancel()
    }
  }, [hoveredNode?.name, props.treemap.mapNodeId, props.treemap.type, props.treemap.dataHash, update])

  const hoveredSparkline = hoveredNode ? sparklineData[hoveredNode.name] : []
  const hasSparkline = hoveredSparkline?.length > 0
  const price = hoveredSparkline?.[hoveredSparkline.length - 1]?.toFixed(2) ?? ''
  const isSmall = visibleNodes.length > 15
  const title =
    (props.treemap.type !== MapTypeId.World ? hoveredNode?.parent.parent.name + ' - ' : '') + hoveredNode?.parent.name
  const backgroundColor = hoveredNode ? props.treemap.colorScale(hoveredNode.perf) : undefined

  return (
    <div
      ref={refs.setFloating}
      id="hover"
      className={classnames('pointer-events-none absolute left-0 top-0', { invisible: !hoveredNode || !isPositioned })}
      style={floatingStyles}
      {...getFloatingProps()}
    >
      <h3 className="text-left">{title}</h3>
      <table className={isSmall ? 'is-small' : ''}>
        <tbody>
          {hoveredNode && (
            <>
              <tr className="hovered" style={{ backgroundColor }}>
                <td className="ticker">{hoveredNode.data?.data?.nameOverride ?? hoveredNode.name}</td>
                <td>
                  {hasSparkline && (
                    <Sparkline className="white" width={65} height={25} data={sparklineData[hoveredNode.name]} />
                  )}
                </td>
                <td className="price">{price}</td>
                <td className="change">{formatPerf(hoveredNode, props.treemap)}</td>
              </tr>
              <tr
                className={classnames('hovered is-description', { 'is-empty': !hoveredNode.description })}
                style={{ backgroundColor }}
              >
                <td colSpan={4} className={classnames({ description: hoveredNode.description })}>
                  {hoveredNode.description}
                </td>
              </tr>
            </>
          )}

          {visibleNodes.length > 1 &&
            visibleNodes.map((c) => {
              const hasSparkline = sparklineData?.[c.name]?.length > 0
              const data = hasSparkline ? sparklineData[c.name] : []
              return (
                <tr key={c.name}>
                  <td className="ticker text-gray-900">{c.data?.data?.nameOverride ?? c.name}</td>
                  <td>
                    {hasSparkline && <Sparkline width={65} height={isSmall ? 20 : 25} data={sparklineData[c.name]} />}
                  </td>
                  <td className="price text-gray-900">{hasSparkline ? data[data.length - 1].toFixed(2) : ''}</td>
                  <td className="change" style={{ color: props.treemap.colorScale(c.perf) }}>
                    {formatPerf(c, props.treemap)}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}
