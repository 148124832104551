import classNames from 'classnames'
import Hammer from 'hammerjs'
import * as React from 'react'

import { isFirefoxDesktop } from '../../shared/isMobile'
import { loadImage } from '../../shared/loadImage'
import { retinafy } from '../../shared/retinafy'
import LayoutGenerator from '../layout-generator'
import MapActionCreators from '../store/action-creators'
import mapActionCreators from '../store/action-creators'
import MapStore from '../store/mapStore'
import Treemap from '../treemap'
import { MapDataIndustry, MapDataNode, MapDataSector, MapTypeId, NodeDepth } from '../types'
import { getOffset } from '../utils'
import CanvasHover from './CanvasHover'
import Legend from './Legend'

interface CanvasProps {
  alt?: string
  treemap: Treemap
  zoom?: boolean
  legend?: boolean
  zoomOnWheel?: boolean
  hover?: boolean
  onNodeClick?: (props: {
    event: React.MouseEvent<HTMLCanvasElement>
    treemap: Treemap
    node?: MapDataNode
    industry?: MapDataIndustry
    sector?: MapDataSector
  }) => void
}

interface CanvasState {
  zoom: number
  initialized: boolean
  type: MapTypeId
  dataHash: string
  width: number
  height: number
}

// TODO presunt w, h, zoom, translate do stavu
class Canvas extends React.Component<CanvasProps, CanvasState> {
  static defaultProps = {
    zoom: true,
    legend: true,
    hover: true,
    zoomOnWheel: true,
  }

  state: CanvasState = {
    zoom: 1,
    initialized: false,
    type: this.props.treemap.type,
    dataHash: this.props.treemap.dataHash,
    width: this.props.treemap.width,
    height: this.props.treemap.height,
  }

  _canvas = React.createRef<HTMLCanvasElement>()
  _hoverCanvas = React.createRef<HTMLCanvasElement>()

  // @ts-ignore
  _canvasContext: CanvasRenderingContext2D
  // @ts-ignore
  _hoverContext: CanvasRenderingContext2D

  _cache: Record<string, { canvas: HTMLCanvasElement; context: CanvasRenderingContext2D }> = {}
  mouseDown: boolean = false
  lastPanX: number = 0
  lastPanY: number = 0

  animationFrameId: number | null = null

  // @ts-ignore
  worldBackground: HTMLImageElement

  constructor(props: CanvasProps) {
    super(props)

    this._cache = {}

    for (var event of [
      '_onChange',
      '_onMouseDown',
      '_onMouseMove',
      '_onMouseUp',
      '_onMouseLeave',
      '_onDoubleClick',
      '_onWheel',
      '_onPanStart',
      '_onPanMove',
      '_onPinch',
      '_getPublishCanvas',
    ]) {
      // @ts-ignore
      this[event] = this[event].bind(this)
    }
  }

  componentDidMount() {
    MapStore.addChangeListener(this._onChange)
    mapActionCreators._setOnPublish(this._getPublishCanvas)

    this._initialize()
  }

  componentDidUpdate() {
    if (!this.state.initialized) return

    const { treemap } = this.props
    const { dataHash, type } = this.state

    // Nothing changed from outside, so we just render the current view
    if (
      // Data update
      treemap.dataHash === dataHash &&
      // Type update
      type === treemap.type &&
      // Window Resize
      treemap.width === this.state.width &&
      treemap.height === this.state.height
    ) {
      this.renderFromCache(this._canvasContext)
      this.renderHover(this._hoverContext)
      return
    }

    this._setCanvasSize(treemap.width, treemap.height)

    if (!this.worldBackground && treemap.type === MapTypeId.World) {
      this._loadTreemapGeoBackground().then(() => this._updateCanvasCaches(treemap))
    } else {
      this._updateCanvasCaches(treemap)
    }
  }

  componentWillUnmount() {
    // TODO remove hammer listener
    MapStore.removeChangeListener(this._onChange)
    this._hoverCanvas.current?.removeEventListener('wheel', this._onWheel)
  }

  render() {
    const { initialized } = this.state
    const { alt, treemap, legend, hover, zoom } = this.props

    return (
      <div id="canvas-wrapper">
        <canvas ref={this._canvas} className={classNames('chart', { initialized })}>
          {alt}
        </canvas>
        {(hover || zoom) && (
          <canvas
            ref={this._hoverCanvas}
            className="hover-canvas absolute left-0 top-0"
            onMouseDown={this._onMouseDown}
            onMouseMove={this._onMouseMove}
            onMouseUp={this._onMouseUp}
            onMouseLeave={this._onMouseLeave}
            onDoubleClick={this._onDoubleClick}
          />
        )}
        {legend && <Legend key={treemap.scale.id} treemap={treemap} className="pt-4" />}
        {hover && <CanvasHover treemap={treemap} />}
      </div>
    )
  }

  renderFromCache(context: CanvasRenderingContext2D) {
    const { treemap } = this.props
    const { zoom } = this.state
    const [x, y] = treemap.zoom.translate()
    const nearestSize = treemap.zoom.getNearestSize(zoom)

    // Last resort if the cache doesn’t exist
    if (!this._cache[nearestSize]) this._createCache(nearestSize)

    context.save()
    context.drawImage(this._cache[nearestSize].canvas, ~~x, ~~y, ~~(treemap.width * zoom), ~~(treemap.height * zoom))
    context.restore()
  }

  _onChange() {
    const { treemap } = this.props

    this.setState({ zoom: treemap.zoom.scale() })
  }

  _onMouseDown() {
    this.mouseDown = true
  }

  _onMouseMove(e: React.MouseEvent<HTMLCanvasElement>) {
    if (this.mouseDown || !this.props.hover) return

    const { treemap } = this.props
    var { offsetX, offsetY } = getOffset(e)
    let nodeAtPosition = treemap.getNodeAtPosition(offsetX, offsetY)

    if (!nodeAtPosition) {
      const industryAtPosition = treemap.getIndustryAtPosition(offsetX, offsetY)
      nodeAtPosition = industryAtPosition?.children[0]
    }

    MapActionCreators.setHoveredNode(treemap.mapNodeId, nodeAtPosition)
  }

  _onMouseUp() {
    this.mouseDown = false
  }

  _onMouseLeave() {
    MapActionCreators.setHoveredNode(this.props.treemap.mapNodeId, undefined)
  }

  _onDoubleClick(e: React.MouseEvent<HTMLCanvasElement>) {
    const { treemap } = this.props
    const { offsetX, offsetY } = getOffset(e)
    const nodeAtPosition = treemap.getNodeAtPosition(offsetX, offsetY)
    const industryAtPosition = treemap.getIndustryAtPosition(offsetX, offsetY)
    const sectorAtPosition = treemap.getSectorAtPosition(offsetX, offsetY)
    this.props.onNodeClick?.({
      event: e,
      treemap,
      node: nodeAtPosition,
      industry: industryAtPosition,
      sector: sectorAtPosition,
    })
  }

  _onWheel(e: MouseEvent) {
    if (!this.props.zoom) return

    e.preventDefault()

    if (!this.state.initialized) {
      return
    }

    var { offsetX, offsetY } = getOffset(e)
    // @ts-ignore
    MapActionCreators.zoom(this.props.treemap, -e.deltaY, offsetX, offsetY)
  }

  _onPanStart(e: any) {
    if (!this.state.initialized) {
      return
    }

    this.lastPanX = e.pointers[0].clientX
    this.lastPanY = e.pointers[0].clientY
  }

  _onPanMove(e: any) {
    if (!this.state.initialized) {
      return
    }

    var movementX = this.lastPanX - e.pointers[0].clientX
    var movementY = this.lastPanY - e.pointers[0].clientY
    this.lastPanX = e.pointers[0].clientX
    this.lastPanY = e.pointers[0].clientY
    const { treemap } = this.props
    MapActionCreators.changeTranslate(treemap, movementX, movementY)
  }

  _onPinch(e: any) {
    if (!this.state.initialized || !this.props.zoom || e.pointers.length < 2) {
      return
    }

    var offsetX1 = e.pointers[0].clientX - e.target.offsetLeft - e.target.offsetParent.offsetLeft, // TODO fix scrolled
      offsetY1 = e.pointers[0].clientY - e.target.offsetTop - e.target.offsetParent.offsetTop,
      offsetX2 = e.pointers[1].clientX - e.target.offsetLeft - e.target.offsetParent.offsetLeft,
      offsetY2 = e.pointers[1].clientY - e.target.offsetTop - e.target.offsetParent.offsetTop,
      offsetX = (offsetX1 + offsetX2) / 2,
      offsetY = (offsetY1 + offsetY2) / 2
    var direction = e.scale >= 1 ? 1 : -1
    MapActionCreators.zoom(this.props.treemap, direction, offsetX, offsetY)
  }

  _setCanvasContexts() {
    const canvas = this._canvas.current!
    const canvasContext = canvas.getContext('2d', isFirefoxDesktop() ? { willReadFrequently: true } : undefined)!
    this._canvasContext = canvasContext

    if (this.props.hover || this.props.zoom) {
      const hoverCanvas = this._hoverCanvas.current!
      const hoverContext = hoverCanvas.getContext('2d', isFirefoxDesktop() ? { willReadFrequently: true } : undefined)!

      this._hoverContext = hoverContext
    }
  }

  _setCanvasSize(width: number, height: number) {
    retinafy(this._canvas.current, this._canvasContext, width, height)

    if (this.props.hover || this.props.zoom) {
      retinafy(this._hoverCanvas.current, this._hoverContext, width, height)
    }
  }

  async _initialize() {
    const { treemap, zoomOnWheel } = this.props

    this._setCanvasContexts()
    this._setCanvasSize(treemap.width, treemap.height)

    const zoomLevels = treemap.getZoomLevels()
    var render = () => {
      this._createCacheWithPriority(zoomLevels, zoomLevels[0], () => {
        this.setState({ initialized: true })

        const hoverCanvas = this._hoverCanvas.current

        if (!hoverCanvas) return

        var hammertime = new Hammer(hoverCanvas)
        hammertime.get('pinch').set({ enable: true, threshold: 0.1 })
        hammertime.on('panstart', this._onPanStart)
        hammertime.on('panmove', this._onPanMove)
        hammertime.on('pinch', this._onPinch)

        if (zoomOnWheel) {
          hoverCanvas.addEventListener('wheel', this._onWheel, { passive: false })
        }
      })
    }

    if (treemap.type === MapTypeId.World) {
      await this._loadTreemapGeoBackground()
      render()
    } else {
      render()
    }
  }

  async _getTreemapGeoBackground() {
    if (this.props.treemap.getIsSmall()) {
      return FinvizSettings.hasDarkTheme
        ? import('../assets/map_geo_small_dark@2x.png')
        : import('../assets/map_geo_small@2x.png')
    }

    return import('../assets/map_geo.png')
  }

  async _loadTreemapGeoBackground() {
    try {
      const image = await this._getTreemapGeoBackground()
      this.worldBackground = await loadImage(image.default)
    } catch {}
  }

  _createCache(scale: number) {
    const { treemap } = this.props

    const oldScale = treemap.zoom.scale()
    const oldTranslate = treemap.zoom.translate()
    const width = ~~(treemap.width * scale)
    const height = ~~(treemap.height * scale)

    if (!this._cache[scale]) {
      const cacheCanvas = document.createElement('canvas')
      const cacheContext = cacheCanvas.getContext('2d', isFirefoxDesktop() ? { willReadFrequently: true } : undefined)!

      this._cache[scale] = { canvas: cacheCanvas, context: cacheContext }
    }

    retinafy(this._cache[scale].canvas, this._cache[scale].context, width, height)

    treemap.zoom.scale(scale)
    treemap.zoom.translateAbs([0, 0])
    this.renderCanvas(this._cache[scale].context, treemap)
    treemap.zoom.scale(oldScale)
    treemap.zoom.translateAbs(oldTranslate)
  }

  _updateCanvasCaches(treemap: Treemap) {
    this._createCacheWithPriority(treemap.getZoomLevels(), treemap.zoom.getNearestSize(), () => {
      this.setState((prevState) => ({
        dataHash: treemap.dataHash,
        width: treemap.width,
        height: treemap.height,
        type: treemap.type,
        zoom: treemap.type !== prevState.type ? 1 : prevState.zoom,
      }))

      // Reset zoom if we’re changing map type
      if (this.state.type !== treemap.type) {
        mapActionCreators.setHoveredNode(treemap.mapNodeId, undefined)
        mapActionCreators.resetSparklineData()
      }

      this.renderFromCache(this._canvasContext)
    })
  }

  _createCacheWithPriority(zoomLevels: number[], currentZoom: number, callback?: () => void) {
    if (this.animationFrameId) window.cancelAnimationFrame(this.animationFrameId)

    const remainingLevels = zoomLevels.filter((level) => level !== currentZoom)
    this.animationFrameId = requestAnimationFrame(() => {
      this._createCache(currentZoom)
      callback?.()
      this._queueUpdateCache(remainingLevels)
    })
  }

  _queueUpdateCache(scales: number[], currentScale = 0) {
    if (!scales[currentScale]) return

    this.animationFrameId = requestAnimationFrame(() => {
      this._createCache(scales[currentScale])
      this._queueUpdateCache(scales, currentScale + 1)
    })
  }

  renderHover(context: CanvasRenderingContext2D) {
    if (!this.props.hover) return

    const { treemap } = this.props
    const { hover } = treemap.settings.industry
    const hoveredNode = MapStore.getHoveredNode(treemap.mapNodeId)
    const industry = hoveredNode?.parent

    context.save()

    context.clearRect(0, 0, treemap.width, treemap.height)
    context.translate.apply(context, treemap.zoom.translate())
    context.scale(treemap.zoom.scale(), treemap.zoom.scale())

    if (!industry) {
      context.restore()
      return
    }

    const showIndustryHeader =
      LayoutGenerator.isNodeHeaderVisible(industry, treemap.settings) && industry.depth === NodeDepth.Industry
    let industryOffset =
      treemap.getIsSmall() || showIndustryHeader
        ? treemap.settings.industry.padding.top
        : LayoutGenerator.smallIndustryPadding.top

    if (industry.depth === NodeDepth.Sector) industryOffset = treemap.settings.sector.header.height

    if (showIndustryHeader && !treemap.getIsSmall()) {
      industryOffset += 1.5
    }

    // Outter border
    context.strokeStyle = hover?.border ?? 'transparent'
    context.lineWidth = 3
    context.strokeRect(industry.x - 0.5, industry.y - 0.5 + industryOffset, industry.dx, industry.dy - industryOffset)

    // Inner borders
    context.translate(0.5, 0.5)
    context.lineWidth = 1
    context.beginPath()
    for (let i = 0; i < industry.children.length; i++) {
      const node = industry.children[i]
      context.moveTo(node.x - 1, node.y - 1)
      context.lineTo(node.x + node.dx - 1, node.y - 1)
      context.lineTo(node.x + node.dx - 1, node.y + node.dy - 1)
      context.lineTo(node.x - 1, node.y + node.dy - 1)
      context.lineTo(node.x - 1, node.y - 1.5)
    }
    context.stroke()

    // Header and text
    if (!treemap.getIsSmall() && showIndustryHeader) {
      context.translate(-0.5, -0.5)
      treemap.renderIndustryHeader({
        node: industry,
        context,
        config: hover,
        parent: treemap.settings.industry,
        fill: hover?.background ?? treemap.settings.background,
      })
      treemap.renderNodeText({ node: industry, context, config: hover, parent: treemap.settings.industry })
    }

    context.restore()
  }

  renderCanvas(context: CanvasRenderingContext2D, treemap: Treemap) {
    context.save()

    const scale = treemap.zoom.getNearestSize()

    context.fillStyle = treemap.settings.background
    context.fillRect(0, 0, treemap.width * scale, treemap.height * scale)

    context.translate.apply(context, treemap.zoom.translate())
    context.scale(scale, scale)

    /* BACKGROUND */
    if (treemap.type === MapTypeId.World && this.worldBackground) {
      if (treemap.getIsSmall()) context.translate(0, 20)

      context.drawImage(
        this.worldBackground,
        0,
        0,
        this.worldBackground.naturalWidth,
        this.worldBackground.naturalHeight,
        0,
        20,
        treemap.width,
        treemap.width / (this.worldBackground.naturalWidth / this.worldBackground.naturalHeight)
      )
    }

    if (treemap.settings.sector.background) {
      context.fillStyle = treemap.settings.sector.background
      for (let index = 0; index < treemap.sectors.length; index++) {
        const node = treemap.sectors[index]
        context.fillRect(node.x, node.y, node.dx, node.dy)
      }
    }

    if (treemap.settings.industry.background) {
      context.fillStyle = treemap.settings.industry.background
      for (let index = 0; index < treemap.industries.length; index++) {
        const node = treemap.industries[index]
        context.fillRect(node.x, node.y + 3, node.dx - 1, node.dy - 4)
      }
    }

    /* STOCKS */
    for (let index = 0; index < treemap.nodes.length; index++) {
      const node = treemap.nodes[index]
      if (!node.parent) continue // Skip on root node
      treemap.renderStockNode(treemap.nodes[index], context)
    }

    /* INDUSTRIES */
    for (let index = 0; index < treemap.industries.length; index++) {
      const node = treemap.industries[index]
      const isLargeNode = LayoutGenerator.isNodeHeaderVisible(node, treemap.settings)
      const isGeoMap = treemap.type === MapTypeId.World
      const renderBackground = treemap.getIsSmall() ? isGeoMap : !isGeoMap

      if (!isLargeNode || (treemap.getIsSmall() && !isGeoMap)) continue

      if (Number.isFinite(node.perf) && renderBackground) {
        treemap.renderIndustryHeader({
          node,
          context,
          config: treemap.settings.industry.header,
          parent: treemap.settings.industry,
          fill: treemap.colorScale(node.perf),
        })
      }

      treemap.renderNodeText({
        node,
        context,
        config: treemap.settings.industry.header,
        parent: treemap.settings.industry,
      })
    }

    /* SECTORS */
    if (treemap.type !== MapTypeId.World) {
      for (let index = 0; index < treemap.sectors.length; index++) {
        const node = treemap.sectors[index]

        if (treemap.getIsSmall()) {
          treemap.renderIndustryHeader({
            node,
            context,
            config: treemap.settings.sector.header,
            parent: treemap.settings.sector,
            fill: treemap.settings.sector.header.background ?? treemap.colorScale(node.perf),
          })
        }

        treemap.renderNodeText({
          node,
          context,
          config: treemap.settings.sector.header,
          parent: treemap.settings.sector,
        })
      }
    }

    context.restore()

    if (treemap.settings.sector.border) {
      this.renderSectorBorders(context, treemap)
    }
  }

  renderSectorBorders(context: CanvasRenderingContext2D, treemap: Treemap) {
    const scale = treemap.zoom.scale()
    context.lineWidth = 2 * scale
    context.strokeStyle = treemap.settings.sector.border ?? 'transparent'

    context.beginPath()

    // Outside border
    context.moveTo(0, 0)
    context.lineTo(treemap.width * scale, 0)
    context.lineTo(treemap.width * scale, treemap.height * scale)
    context.lineTo(0, treemap.height * scale)
    context.lineTo(0, -1)

    for (let index = 0; index < treemap.sectors.length; index++) {
      const sector = treemap.sectors[index]

      context.strokeRect(sector.x * scale, sector.y * scale, sector.dx * scale, sector.dy * scale)
    }

    context.stroke()
  }

  _getPublishCanvas() {
    return this._cache[1].canvas
  }
}

export default Canvas
