import classnames from 'classnames'
import * as React from 'react'

interface TopbarProps {
  className?: string
  navContent?: React.ReactNode
  contentClass?: string
  mapControls?: React.ReactNode
}

export function Topbar(props: React.PropsWithChildren<TopbarProps>) {
  return (
    <div className={classnames(props.className, 'mb-0.5 flex h-10 items-center')}>
      {props.navContent && (
        <div
          className={classnames(
            props.contentClass,
            'map-view-switch flex w-50 shrink-0 items-center self-stretch px-4'
          )}
        >
          {props.navContent}
        </div>
      )}
      <div className="flex min-h-full grow items-center border-l border-inherit pl-5">{props.children}</div>
      {props.mapControls}
    </div>
  )
}
